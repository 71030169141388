import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/ballarat_care_story_large.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Customer story: How Camelo helps Ballarat Care manage support workers"
          description="Find out how the Camelo employee scheduling app transforms shift scheduling, timesheets, & communication for Ballarat Care, a team of support workers."
        ></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Camelo helps Ballarat Care manage support workers
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://ballaratcare.com.au/" target="__blank">
                      {`Ballarat Care `}
                    </Link>
                    is a small team of professional and qualified support
                    workers delivering person-centred care to clients. They
                    cater private funded clients and NDIS self or plan managed
                    participants. Mim, the founder of Ballarat Care, used to go
                    through a very slow scheduling process. She had to text each
                    employee to let them know about their shifts. When she found
                    out about Camelo, she realized she didn’t have to waste so
                    much time on scheduling work anymore.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Giving people the professional care they need
                  </h4>
                  <p className="gr-text-8 text-black">
                    Mim, the founder of Ballarat Care, has worked in the health
                    and aged care industries for years. With a nursing
                    background, she has profound experience in looking after a
                    wide variety of people—the elderly, people with physical and
                    intellectual disability, acutely unwell patients, and more.
                  </p>
                  <p className="gr-text-8 text-black">
                    With the experience she has and the goals of providing
                    high-quality support services to the Ballarat community, Mim
                    created Ballarat Care.
                  </p>
                  <p className="gr-text-8 text-black">
                    From personal care support and domestic support to community
                    support and nursing care, Ballarat Care makes sure clients
                    are put first and well taken care of.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Overseeing employees is challenging
                  </h4>
                  <p className="gr-text-8 text-black">
                    Running a healthcare business is not an easy job. There are
                    so many challenges Mim has to face. One of them is
                    overseeing employees.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “Overseeing people is the most challenging job for me.
                      Each employee has their own individuality. Taking people
                      on board and making sure they share the same values as you
                      do is a continuing process.”—Mim shared.
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    While managing employees, Mim constantly faces a difficulty
                    that hinders her progress—scheduling work.
                  </p>

                  <h4 className="pt-8 pb-4">Scheduling used to be slow</h4>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “Prior to using Camelo, I do the scheduling manually. It's
                      either by using a whiteboard or a calendar app on my
                      device."
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    To notify employees about their shifts, Mim had to send
                    texts to each of them, one by one.
                  </p>
                  <p className="gr-text-8 text-black">
                    It was surely a slow process. It involved a lot of
                    back-and-forth communication to get employees to confirm
                    their shifts.
                  </p>

                  <h4 className="pt-8 pb-4">A better way to scheduling</h4>
                  <p className="gr-text-8 text-black">
                    Mim then found out about Camelo on social media.{" "}
                  </p>
                  <p className="gr-text-8 text-black">
                    When she first tried out the app, she was impressed that
                    Camelo was so user-friendly. She could navigate easily and
                    find all the tools necessary for her admin work.
                  </p>
                  <p className="gr-text-8 text-black">
                    And her initial problem was solved. She didn’t have to text
                    each employee anymore.{" "}
                  </p>
                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-4 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        “With Camelo, once I popped in their shifts, I can then
                        notify them straight away. Also, they are able to
                        confirm if they are happy with them.“
                      </p>
                    </blockquote>
                  </p>
                  <p className="gr-text-8 text-black">
                    Mim also likes the open shift feature on the software. The
                    feature allows employees to select shifts that align with
                    their preferred working times, giving everyone autonomy and
                    flexibility in their work schedules.
                  </p>

                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-4 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        “What I like about Camelo too is the open shift. If an
                        open shift comes up, anyone can see it!”
                      </p>
                    </blockquote>
                  </p>
                  <p className="gr-text-8 text-black">
                    Over time, as all the features she needs are in the app, Mim
                    chooses to stick with it. Camelo becomes an essential tool
                    in streamlining her business operations and managing her
                    employees.
                  </p>
                  <p className="gr-text-8 text-black">
                    When asked if she would recommend Camelo to others, Mim told
                    us, “Yes 100%. It has all the features you will need!”
                  </p>
                  <h4 className="pt-8 pb-4">
                    Shifting time from admin tasks to business priorities{" "}
                  </h4>
                  <p className="gr-text-8 text-black">
                    Not only Camelo helps with rostering, but it also helps
                    record timesheets automatically, which is convenient for
                    payroll processing and admin work.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Since using Camelo, I have saved a lot of time doing
                      rostering and timesheets. It is heaven sent for my small
                      business.“
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    With all the time saved, Mim can shift her focus toward
                    other crucial aspects of her business. Rather than being
                    overwhelmed by admin tasks, Mim can now concentrate on
                    improving the quality of care provided to her clients.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “What I like most about Camelo is that it’s convenient and
                      less stress for admin work.”
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    A meaningful journey with solid beliefs and firm support
                  </h4>
                  <p className="gr-text-8 text-black">
                    Despite the difficulties, offering support work is still one
                    of the most rewarding jobs Mim has ever been into.
                  </p>
                  <p className="gr-text-8 text-black">
                    Mim and her team firmly believe that providing clients with
                    "Choice and Control" over their lives enables them to reach
                    their full potential. The team also believes in equality for
                    all—with disability or not.
                  </p>
                  <p className="gr-text-8 text-black">
                    With the beliefs and understanding of the unique needs and
                    preferences of each client group, Ballarat Care is actively
                    and passionately delivering tailored, high-quality
                    assistance and care to those in need.
                  </p>
                  <p className="gr-text-8 text-black">
                    And Camelo is proud to be one firm source of support
                    throughout the team’s journey of solidifying their beliefs
                    and reaching their business goals.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “I am very grateful for Camelo being the best scheduling
                      app on the market. It has taken my small business to the
                      next level.”
                    </p>
                    <footer className="blockquote-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                          <div>
                            Mim
                            <span> (Owner)</span>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
